import React from "react";
import Layout from "../components/layout";
import styled, { css } from "styled-components";
import { Container, Col, Row } from "../components/container";
import Heading from "../components/heading";
import { Button, ButtonGroup } from "../components/button";
import calendar from "../images/icons/Calendar.svg";
import invite from "../images/icons/Invite.svg";
import tablet from "../images/icons/Medication/Tablet.svg";
import feedback from "../images/icons/Feedback.svg";
import postcode from "../images/icons/Postcode.svg";
import notes from "../images/icons/Notes.svg";

const items = [
  {
    title: "Diet",
    description: "See recipes and tips to help you manage your diet.",
    icon: calendar
  },
  {
    title: "Record sharing",
    description:
      "We can send your latest readings to your healthcare team for review",
    icon: invite
  },
  {
    title: "Medication",
    description: "Find out more about common diabetes treatments",
    icon: tablet
  },
  {
    title: "Support",
    description: "Speak to an expert for information and advice",
    icon: feedback
  },
  {
    title: "Travel",
    description: "Information and tools if you're planning to take a trip",
    icon: postcode
  },
  {
    title: "Courses",
    description: "Structured courses for you to work through in your own time",
    icon: notes
  }
];

const StyledHeading = styled(Heading)`
  padding-top: ${props => props.theme.spacing.verySmall};
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledParagraph = styled.p`
  padding-top: ${props => props.theme.spacing.regular};
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.veryLarge};
  margin-bottom: ${props => props.theme.spacing.huge};
`;

const StyleListItemDescription = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.large} 0;
  border-radius: ${props => props.theme.radius};

  img {
    margin-left: 15px;
    margin-top: 5px;
  }

  ${props =>
    props.active &&
    css`
      background-color: ${props.theme.color.focusHighlight};

      img {
        filter: grayscale(100%);
      }
    `}
`;

const StyledListRow = styled(Row)`
  margin-top: ${props => props.theme.spacing.large};
`;

const ListItem = ({ text, description, icon, active, ...props }) => {
  return (
    <React.Fragment>
      <ListItemContainer active={active} {...props}>
        <Col size={1 / 5}>
          <img src={icon} alt={text} />
        </Col>
        <Col size={7 / 10}>
          <strong>{text}</strong>
          <StyleListItemDescription>{description}</StyleListItemDescription>
        </Col>
      </ListItemContainer>
    </React.Fragment>
  );
};

const Notifications = styled.div`
  width: 100%;
  color: #fff;
  background-color: rgb(202, 80, 80);
  text-align: center;
  padding: ${props => props.theme.spacing.large} 0;
  border-radius: ${props => props.theme.radius};
  margin-top: ${props => props.theme.spacing.large};
  font-weight: 700;
`;

const Block = styled.div`
  width: 100%;
  background-color: rgb(216, 216, 216);
  color: ${props => props.theme.color.textDefault};
  font-weight: 700;
  text-align: center;
  padding: ${props => props.theme.spacing.huge} 0;
  border-radius: ${props => props.theme.radius};
  margin-top: ${props => props.theme.spacing.large};
`;

const StyledSecondaryHeading = styled(Heading)`
  margin-top: ${props => props.theme.spacing.large};
`;

const StyledSubtitle = styled.p`
  margin-top: 0;
`;

const StyledVideo = styled.iframe`
  width: 100%;
  height: 250px;
  outline: 0;
  border: 0;
`;

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interest: {}
    };
  }

  render() {
    return (
      <Layout
        leftButtonLink="/interests"
        leftButtonText="Back"
        rightButtonText="Help"
        rightButtonLink="/"
      >
        <Container>
          <Row>
            <Col>
              <StyledHeading elem="h1" size={Heading.sizes.veryLarge}>
                Diabetes passport
              </StyledHeading>
              <Notifications>Notifications</Notifications>
              <Block>Blood glucose graph</Block>
              <Block>Progress graph</Block>
              <StyledSecondaryHeading subtitle={true} elem="h1" size={Heading.sizes.veryLarge}>
                Introduction into diabetes
              </StyledSecondaryHeading>
              <StyledSubtitle>
                Your next lesson
              </StyledSubtitle>
              <StyledVideo
                src="https://player.vimeo.com/video/295175028?title=0&byline=0"
                width="640"
                height="360"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
              <Block>Symptom quick add</Block>
            </Col>
          </Row>
          <StyledListRow>
            <Col>
              {items.map((item, k) => (
                <ListItem
                  key={k}
                  text={item.title}
                  description={item.description}
                  icon={item.icon}
                />
              ))}
            </Col>
          </StyledListRow>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
